import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import reportWebVitals from './utils/reportWebVitals';
import {ErrorBoundary} from "./components/common/error/error.boundary";
import './utils/i18n';
import {FirebaseDatabaseProvider} from "@react-firebase/database";
import config from "./utils/firebase.config";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {FirebaseAuthProvider} from "@react-firebase/auth";
import './index.css'
import {ThemeModeProvider} from "./providers/theme/theme-provider.context";

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <FirebaseAuthProvider firebase={firebase} {...config}>
                <FirebaseDatabaseProvider {...config} firebase={firebase}>
                    <ThemeModeProvider>
                        <App/>
                    </ThemeModeProvider>
                </FirebaseDatabaseProvider>
            </FirebaseAuthProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
