interface FirebaseConfig {
    appId: string;
    apiKey: string;
    projectId: string;
    databaseURL: string;
    messagingSenderId: string;
    storageBucket: string;
    authDomain: string;
}

const config: FirebaseConfig = {
    appId: process.env.REACT_APP_ID!,
    apiKey: process.env.REACT_APP_API_KEY!,
    projectId: process.env.REACT_APP_PROJECT_ID!,
    databaseURL: process.env.REACT_APP_DATABASE_URL!,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID!,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET!,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
}

export default config;
