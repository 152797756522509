import React, {memo, useEffect, useState} from 'react';
import {LanguagesEnum} from "../../../utils/i18n";
import {useTranslation} from "react-i18next";
import {Fade, Menu, MenuItem, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LangItemComponent from "./lang-item.component";

const I18NMenu = memo(
    () => {
        const {i18n, t} = useTranslation()
        const [lang, setLang] = useState<LanguagesEnum>(i18n.language as LanguagesEnum)
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = async (lang: LanguagesEnum) => {
            try {
                setLang(lang)
                localStorage.setItem('i18n', lang)
                await i18n.changeLanguage(lang)
                setAnchorEl(null);
            } catch (e) {
                throw (e)
            }
        };
        useEffect(
            () => {
                const setPreloadLang = async () => {
                    const preloadLang = localStorage.getItem('i18n') as LanguagesEnum;
                    if (preloadLang && i18n.language && preloadLang !== i18n.language) {
                        await i18n.changeLanguage(preloadLang)
                        setLang(preloadLang)
                    }
                }
                setPreloadLang()
            },
            [i18n]
        )
        return (
            <React.Fragment>
                <Tooltip title={t('set_language')!}>
                    <IconButton aria-controls="i18n-menu"
                                aria-haspopup="true"
                                onClick={handleClick}>
                        <LangItemComponent country={lang === LanguagesEnum.it ? LanguagesEnum.it : LanguagesEnum.en}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    id="i18n-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                >
                    <MenuItem
                        value={'it-IT'}
                        onClick={() => handleClose(LanguagesEnum.it)}
                        selected={lang === LanguagesEnum.it}>
                        <LangItemComponent country={LanguagesEnum.it} square/>
                    </MenuItem>
                    <MenuItem
                        value={'en-US'}
                        onClick={() => handleClose(LanguagesEnum.en)}
                        selected={lang === LanguagesEnum.en}>
                        <LangItemComponent country={LanguagesEnum.en} square/>
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
);

export default I18NMenu;
