
export class ErrorHandler {
  public static defaultMessage = 'Errore sconosciuto';

  public static productionMessage = 'Qualcosa è andato storto, per favore riprova';

  public message!: string;

  constructor(e: string) {
    if (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
        // console.log(JSON.parse(JSON.stringify(e)))
      }
      this.message = e;
    }
  }
}
