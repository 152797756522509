import {colors, createTheme} from "@material-ui/core";
import {responsiveFontSizes} from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        h1: {
            fontFamily: 'Roboto Slab, sans-serif',
        },
        h2: {
            fontFamily: 'Roboto Slab, sans-serif',
        },
        h3: {
            fontFamily: 'Roboto Slab, sans-serif',
        },
        h4: {
            fontFamily: 'Roboto Slab, sans-serif',
        },
        h5: {
            fontFamily: 'Roboto Slab, sans-serif',
        },
        h6: {
            fontFamily: 'Roboto Slab, sans-serif',
        }
    },
    palette: {
        type: 'light',
        primary: colors.grey
    },
});

export default responsiveFontSizes(theme)
