import React, {FC} from 'react';
import Box from "@material-ui/core/Box";
import {CircularProgress, CircularProgressProps} from "@material-ui/core";

type Props = {
    size?: number
}
const CircularLoading: FC<Props> = (props: CircularProgressProps) => {
    return (
        <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress {...props}/>
        </Box>
    );
};

export default CircularLoading;
