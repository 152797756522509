import React, {ComponentType, Context, createContext, ReactNode, useCallback, useContext, useReducer,} from 'react';
import {
    ActionsType,
    ThemeActionsType,
    themeInitialState,
    themeProviderActions,
    ThemeState,
} from './theme-provider.actions';

const initialState: ThemeState = themeInitialState;
// combine actions
const reducerActions: ThemeActionsType = themeProviderActions;

const StoreContext: Context<{ state: ThemeState; dispatch: any }> = createContext({
  state: initialState,
  dispatch: undefined,
});

const reducer = (state: ThemeState, newState: ActionsType): ThemeState => {
  const act = reducerActions[newState.type];
  const update = act(state, newState);
  return {
    ...state,
    ...update,
  };
};

export const ThemeModeProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

type ThemeContextReturnType = {
  state: ThemeState;
  dispatch: any;
}

export const useThemeModeProvider: () => ThemeContextReturnType = () => {
  const { state, dispatch } = useContext(StoreContext);
  const useDispatch = useCallback(dispatch, [dispatch]);
  return {
    state: {
      ...state,
      theme: state.theme,
    },
    dispatch: useDispatch,
  };
};

export function withThemeBuilder<T>(Component: ComponentType<T>) {
  return (props: T) => (
    <ThemeModeProvider>
      <Component {...props}/>
    </ThemeModeProvider>
  );
}
