import React, {FC, useCallback} from 'react';
import ListItem from "@material-ui/core/ListItem";
import {Link, useLocation} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {ProjectType} from "./project.types";
import {getI18nValue, LanguagesEnum} from "../../utils/i18n";
import {useTranslation} from "react-i18next";

type Props = {
    project: ProjectType;
    isSignedIn: boolean;
    id: string;
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
const ProjectLink: FC<Props> = ({isSignedIn, project, id, onClick}) => {
    const {state, pathname} = useLocation()
    const {i18n} = useTranslation()
    const lang = i18n.language as LanguagesEnum;
    const isSelected = useCallback(
        (key: string) => {
            try {
                return key ? escape(pathname).search(escape(key)) !== -1 : false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [pathname]
    )
    const route = (id: string, project: ProjectType) => {
        if (isSignedIn) {
            return {
                pathname: `/portfolio/pictures`,
                state: {
                    project: {
                        id,
                        name: project.TITLE,
                        description: project.DESCRIPTION,
                    }
                }
            }
        }
        return {
            pathname: `/project/${getI18nValue(project.TITLE, lang)}`,
            state: {
                ...state,
                project: {
                    id,
                    name: project.TITLE,
                    description: project.DESCRIPTION,
                }
            }
        }
    }
    return (
        <ListItem
            selected={isSelected(getI18nValue(project.TITLE, lang))}
            key={id}
            component={Link}
            onClick={onClick}
            to={route(id, project)}>
            <ListItemText
                primary={
                    <Typography
                        color={"textPrimary"}
                        variant={"overline"}>
                        {getI18nValue(project.TITLE, lang)}
                    </Typography>
                }
                secondary={
                    <Divider component={'span'}/>
                }
            />
        </ListItem>
    )
};

export default ProjectLink;
