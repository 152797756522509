import React, {createContext, Dispatch, useCallback, useContext, useMemo, useReducer,} from 'react';
import merge from 'lodash.merge';
import {
    Actions,
    DialogAction,
    dialogActions,
    DialogActionsType,
    dialogInitialState,
    DialogState,
    FilteringAction,
    filteringActions,
    FilteringActionsType,
    filteringInitialState,
    FilteringState,
    NotificationAction,
    notificationActions,
    NotificationActionsType,
    notificationInitialState,
    NotificationState,
} from './actions';
import {ConfirmDialog, ConfirmDialogProps, SharedDialog} from './components';

// combine initial states
type StateType = NotificationState & FilteringState & DialogState;

type ActionType = NotificationAction | FilteringAction | DialogAction;

const defaultInitialState: StateType = {
    ...notificationInitialState,
    ...filteringInitialState,
    ...dialogInitialState,
};
// combine actions
const ReduceActions: NotificationActionsType & FilteringActionsType & DialogActionsType = {
    ...notificationActions,
    ...filteringActions,
    ...dialogActions,
};

export interface StoreCtx {
    state: StateType;
    dispatch: Dispatch<ActionType>;
}

const Context = createContext<StoreCtx>({state: defaultInitialState} as StoreCtx);

const reducer = (state: StateType, {type, ...newState}: ActionType): StateType => {
    const act = ReduceActions[type];
    const update = act(state, newState as any);
    return {
        ...state,
        ...update,
    };
};

export interface StoreProviderProps {
    initialState?: Partial<StateType>;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({children, initialState}) => {
    const initState = useMemo(() => merge(defaultInitialState, initialState), [initialState]);
    const [state, dispatch] = useReducer(reducer, initState);

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
            <SharedDialog/>
        </Context.Provider>
    );
};

export const useStore = () => {
    const {state, dispatch} = useContext(Context);

    const confirm = useCallback((
        {
            className,
            onCancel,
            onConfirm,
            confirmText,
            cancelText,
            message,
            ...dispatchProps
        }: ConfirmDialogProps & DialogAction,
    ) => {
        const onClose = () => dispatch({
            type: Actions.DIALOG_CLOSE,
        });
        dispatch({
            ...dispatchProps,
            type: Actions.DIALOG_OPEN,
            onClose,
            body: (
                <ConfirmDialog
                    message={message}
                    confirmText={confirmText}
                    cancelText={cancelText}
                    onConfirm={onConfirm}
                    onCancel={() => {
                        if (typeof onCancel === 'function') {
                            onCancel();
                        }
                        onClose();
                    }}
                />
            ),
        });
    }, [dispatch]);

    return {
        state,
        dispatch,
        confirm,
    };
};

export * from './actions/actions';
