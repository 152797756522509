export enum ThemeModeEnum {
    night = 'dark',
    day = 'light',
}

export enum ThemeActions {
    TOGGLE_THEME_MODE = 'TOGGLE_THEME_MODE',
}

export type ActionsType = {
    type: ThemeActions;
}

export type ThemeState = {
    theme: {
        mode: ThemeModeEnum
    };
}

export type ThemeActionType = (state: ThemeState, newState: ActionsType) => ThemeState | Promise<ThemeState>

export type ThemeActionsType = {
    [ThemeActions.TOGGLE_THEME_MODE]: ThemeActionType;
}


export const themeInitialState: ThemeState = {
    theme: {
        mode: localStorage.getItem(ThemeActions.TOGGLE_THEME_MODE) as ThemeModeEnum,
    },
};

const toggleViewMode = (viewMode: ThemeModeEnum) => {
    return viewMode === ThemeModeEnum.day ? ThemeModeEnum.night : ThemeModeEnum.day;
};

export const themeProviderActions: ThemeActionsType = {
    [ThemeActions.TOGGLE_THEME_MODE]: (state, newState) => {
        localStorage.setItem(ThemeActions.TOGGLE_THEME_MODE, toggleViewMode(state.theme.mode))
        return ({
            ...state,
            theme: {
                ...state.theme,
                mode: toggleViewMode(state.theme.mode)
            },
        })
    },
};
