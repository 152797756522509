import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import {ErrorHandler} from "./error.handler";

export interface ErrorProps {
  error: ErrorHandler | string;
}

export const ErrorComponent: React.FC<ErrorProps> = ({ error }) => {
  const e = !(error instanceof ErrorHandler) ? new ErrorHandler(error) : error;

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ margin: 0, width: 'auto' }}>
      <Grid item>
        <Grid container alignItems="center">
          <Warning color="error"/>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="error">
          {e.message}
        </Typography>
      </Grid>
    </Grid>
  );
};
