import React, {memo, useMemo} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {LanguagesEnum} from "../../../utils/i18n";
import {useTheme} from "@material-ui/core";
import DarkItLogo from '../../../assets/pictures/logo-it-dark.png';
import LightItLogo from '../../../assets/pictures/logo-it-light.png';
import DarkEnLogo from '../../../assets/pictures/logo-en-dark.png';
import LightEnLogo from '../../../assets/pictures/logo-en-light.png';

const useStyles = makeStyles(() => ({
    logo: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: 'auto'
    },
}));

const LogoComponent = memo(
    () => {
        const classes = useStyles();
        const theme = useTheme()
        const {i18n} = useTranslation()
        const lang: LanguagesEnum = i18n.language as LanguagesEnum;
        const logo = useMemo(
            () => {
                if (theme.palette.type === 'dark') {
                    return lang === LanguagesEnum.en ? DarkEnLogo : DarkItLogo;
                } else {
                    return lang === LanguagesEnum.en ? LightEnLogo : LightItLogo;
                }
            },
            [lang, theme]
        )
        return (
            <img
                src={logo}
                alt="Paolo Pasqualin Logo"
                className={classes.logo}
            />
        );
    }
);

export default LogoComponent;
