import React, {FC} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {SvgIcon, Tooltip} from "@material-ui/core";
import {ReactComponent as NightLight} from '../../../assets/icons/dark_mode_black_24dp.svg'
import {ReactComponent as ModeNight} from '../../../assets/icons/light_mode_black_24dp.svg'
import {useThemeModeProvider} from "../../../providers/theme/theme-provider.context";
import {ThemeActions, ThemeModeEnum} from "../../../providers/theme/theme-provider.actions";
import {useTranslation} from "react-i18next";

const DarkModeButton: FC = () => {
    const {dispatch, state: {theme: {mode}}} = useThemeModeProvider()
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <Tooltip title={mode === ThemeModeEnum.night ? t('light_mode')! : t('dark_mode')!}>
                <IconButton onClick={() => dispatch({type: ThemeActions.TOGGLE_THEME_MODE})}>
                    <SvgIcon component={mode === ThemeModeEnum.night ? ModeNight : NightLight}/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default DarkModeButton;
