import React, {useMemo, useRef} from 'react';
import {createPortal} from 'react-dom';
import {
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    DialogTitleProps as MuiDialogTitleProps,
    IconButton,
    Slide,
    Snackbar,
    SnackbarProps,
    Typography,
} from '@material-ui/core';
import {Alert, Color} from '@material-ui/lab';
import {Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import clsx from 'clsx';
// eslint-disable-next-line import/no-cycle
import {Actions, useStore} from '../context';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'inherit',
    },
    fullScreenTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        // marginBottom: theme.spacing(2),
        boxShadow: theme.shadows[4],
    },
}));

const FeedbackSnackbar: React.FC<SnackbarProps & { severity?: Color }> = ({severity, message, ...props}) => {
    if (!severity) {
        return (
            <Snackbar {...props} message={message}/>
        );
    }
    return (
        <Snackbar {...props}>
            <Alert severity={severity} elevation={6} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

export interface DialogTitleProps extends MuiDialogTitleProps {
    fullScreen?: boolean;
    onClose?: (...attr: any) => void;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({children, onClose, fullScreen, ...other}) => {
    const classes = useStyles();
    return (
        <MuiDialogTitle
            style={{
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }}
            disableTypography
            {...other}
            className={clsx(other.className, {
                [classes.fullScreenTitle]: fullScreen,
            })}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size={"medium"}
                    className={classes.closeButton}
                    onClick={onClose}>
                    <Close color={"action"}/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export interface SharedDialogProps {
    snackbarParentEl?: any;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SharedDialog: React.FC<SharedDialogProps> = ({snackbarParentEl}) => {
    const {state: {notification, dialog: {body, title, slideTransition, ...dialog}}, dispatch} = useStore();
    const dialogRef = useRef(null)
    const snackbar = useMemo(() => {
        if (!notification) return;

        const el = <FeedbackSnackbar onClose={() => dispatch({type: Actions.TOAST_CLOSE})} {...notification}/>;

        if (snackbarParentEl) {
            // eslint-disable-next-line consistent-return
            return createPortal(el, snackbarParentEl);
        }
        // eslint-disable-next-line consistent-return
        return el;
    }, [notification, snackbarParentEl, dispatch]);
    return (
        <>
            <Dialog
                PaperProps={{square: true}}
                className={dialog.className}
                aria-labelledby="shared-dialog"
                keepMounted
                ref={dialogRef}
                TransitionComponent={slideTransition ? Transition : undefined}
                {...dialog}
            >
                <DialogTitle id="shared-dialog-title" onClose={dialog.onClose} fullScreen={dialog.fullScreen}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>
            </Dialog>
            {snackbar}
        </>
    );
};
