import React, {FC, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import NavbarComponent from "../common/navbar/navbar.component";
import I18NMenu from "../common/i18n-menu/i18n.menu";
import ProjectList from "../project/project.list";
import DarkModeButton from "../common/dark-mode-button/dark-mode.button";
import InstagramButton from "../common/social-buttons/instagram.button";
import {Link} from "react-router-dom";
import {Accordion, AccordionDetails, Grid, ListItem, SwipeableDrawer, useMediaQuery} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import FirebaseLogout from "../common/firebase-logout";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import LogoComponent from "../common/logo/logo.component";

export const drawerWidth = window.innerWidth >= 1024 ? 320 : '100vw';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            border: 'none',
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        accordionDetails: {
            padding: 0,
            paddingLeft: theme.spacing(1),
            backgroundColor: theme.palette.background.default
        },
        accordion: {
            margin: '0!important'
        },
        link: {
            textDecoration: 'unset',
            color: 'unset'
        },
        selected: {
            textDecoration: 'inherit',
        },
        bottomAppBar: {
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            bottom: 0,
            top: 'auto'
        },
        capitalize: {
            textTransform: "capitalize",
            textDecoration: "none"
        },
        menuWrp: {
            [`${theme.breakpoints.down('md')} and (orientation: portrait)`]: {
                marginBottom: '56px'
            },
            [`${theme.breakpoints.up('sm')}`]: {
                marginBottom: '0'
            },
            [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
                marginBottom: '90vh'
            }
        }
    }),
);

type Props = {
    window?: () => Window;
}
const ProjectSiderbarHoc: FC<Props> = (props) => {
    const {window, children} = props;
    const {t} = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const [expanded] = useState(true)
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const drawer = (
        <React.Fragment>
            <AppBar
                color={"inherit"}
                variant={"outlined"}
                position={"sticky"}
                style={{
                    height: '64px',
                    bottom: 'auto',
                    border: 'none',
                }}
                square>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Link
                            to={'/'}
                            onClick={() => setMobileOpen(false)}
                            className={classes.link}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                mt={4}
                                mb={4}
                                mx={3}
                            >
                                <LogoComponent/>
                            </Box>
                        </Link>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item>
                            <IconButton onClick={() => setMobileOpen(false)}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Hidden>
                </Grid>
            </AppBar>
            <Box
                className={classes.menuWrp}
                height={'100%'}
                position={'relative'}>
                <Accordion
                    className={classes.accordion}
                    expanded={expanded}
                    elevation={0}
                    square>
                    {/*<AccordionSummary to={'/portfolio'} component={Link}>
                        <Typography
                            color={"textPrimary"}
                            className={classes.capitalize}
                            variant={"h6"}>
                            {t('portfolio')}
                        </Typography>
                    </AccordionSummary>*/}
                    <AccordionDetails className={classes.accordionDetails}>
                        <List dense style={{width: '100%', padding: 0}}>
                            <ProjectList onClick={
                                (e) => {
                                    e.stopPropagation()
                                    setMobileOpen(false)
                                }}
                            />
                            <ListItem>
                                <Link
                                    to={'/bio'}
                                    className={classes.link}
                                    onClick={() => setMobileOpen(false)}>
                                    <Typography variant={"overline"}>
                                        {t('bio')}
                                    </Typography>
                                </Link>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Box
                    position={mdDown ? 'relative' : 'sticky'}
                    padding={2}
                    pl={3}
                    top={mdDown ? 'auto' : '100%'}
                    bottom={0}
                    left={0}
                    right={0}>
                    <Grid container>
                        <Grid item>
                            <Typography
                                color={"textSecondary"}
                                variant={"caption"}>
                                {t('contact_me_at')}&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                component={'a'}
                                target={'_blank'}
                                rel="noopener"
                                color={"textSecondary"}
                                href={'mailto:paolo.pasqualin@gmail.com'}
                                variant={"caption"}>
                                paolo.pasqualin@gmail.com
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
            <AppBar
                className={classes.bottomAppBar}
                position={"sticky"}
                color={"inherit"}
                variant={"outlined"}
                square>
                <Box>
                    <FirebaseLogout/>
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'}>
                    <I18NMenu/>
                    <DarkModeButton/>
                    <InstagramButton url={'https://www.instagram.com/pasqualin.paolo/'}/>
                </Box>
            </AppBar>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <AppBar
                    position="fixed"
                    color={"inherit"}
                    variant={"outlined"}
                    className={classes.appBar}
                    style={{borderLeft: 'none'}}
                    square>
                    <NavbarComponent handleDrawerToggle={handleDrawerToggle}/>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="Website projects">
                <Hidden mdUp implementation="css">
                    <SwipeableDrawer
                        onOpen={() => setMobileOpen(true)}
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </SwipeableDrawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {children}
            </main>
        </div>
    );
};

export default ProjectSiderbarHoc;
