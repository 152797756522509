import React, {FC, useEffect, useRef} from 'react';
import firebase from "firebase/app";
import useFirebase from "../../hooks/useFirebase";
import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css'
import {Container} from "@material-ui/core";
import {loginContainerStyle} from "../container/login.container";
import {useHistory} from "react-router-dom";
import {FirebaseAuthConsumer} from "@react-firebase/auth";

type Props = {
    refreshUrl?: string
}
const FirebaseClassicLogin: FC<Props> = ({refreshUrl = '/portfolio'}) => {
    const classes = loginContainerStyle()
    const {auth} = useFirebase()
    const {push} = useHistory()
    const FIREBASE_UI = useRef<any>()
    useEffect(
        () => {
            try {
                if (auth) {
                    if (FIREBASE_UI.current) {
                        FIREBASE_UI.current.delete()
                    }
                    FIREBASE_UI.current = new firebaseui.auth.AuthUI(auth);
                    FIREBASE_UI.current.start('#firebaseui-auth-container', {
                        signInOptions: [{
                            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
                            defaultCountry: 'IT',
                        }],
                        signInSuccessUrl: refreshUrl,
                    });
                }
            } catch (e) {
                throw e
            }
        },
        [refreshUrl, auth]
    )
    useEffect(
        () => {
            return () => {
                try {
                    if (FIREBASE_UI.current) {
                        FIREBASE_UI.current.delete()
                    }
                } catch (e) {
                    throw e
                }
            }
        },
        []
    )
    return (
        <Container maxWidth={'sm'}>
            <FirebaseAuthConsumer>
                {({isSignedIn}) => {
                    if (isSignedIn) {
                        push(refreshUrl)
                    }
                    return (
                        <React.Fragment>
                            <div className={classes.background}/>
                            <div id={'firebaseui-auth-container'} className={classes.card}/>
                        </React.Fragment>
                    );
                }}
            </FirebaseAuthConsumer>

        </Container>
    );
};

export default FirebaseClassicLogin;
