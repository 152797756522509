import React, {FC} from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Container, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import Lock from "@material-ui/icons/Lock";

export const loginContainerStyle = makeStyles({
    background: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'aliceblue',
        // background: `#000 url(${LoginBackground}) center center no-repeat`,
        backgroundSize: 'cover',
        filter: 'blur(4px) brightness(50%) grayscale(100%)',
        transform: 'scale(1.1)'
    },
    card: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '50vw',
        width: '90%',
        transform: 'translate(-50%, -50%)'
    }
})
const LoginContainer: FC = () => {
    const classes = loginContainerStyle()
    const {t} = useTranslation()
    return (
        <Container maxWidth={'xs'}>
            <div className={classes.background}/>
            <Card className={classes.card}>
                <CardMedia>
                    <Box mt={3} mx={3}>
                        <Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>
                            <Grid item>
                                <Skeleton width={100} height={100} variant={"circle"}/>
                            </Grid>
                            <Grid item>
                                <Skeleton width={150} height={30}/>
                                <Skeleton width={150} height={30}/>
                                <Skeleton width={150} height={30}/>
                            </Grid>
                        </Grid>
                    </Box>
                </CardMedia>
                <CardContent>
                    <Typography variant={"h5"} color={"textSecondary"} align={"center"}>
                        {t('expired_session')}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        color={"primary"}
                        size={"large"}
                        startIcon={<Lock/>}
                        variant={"contained"}
                        fullWidth>
                        {t('login')}
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default LoginContainer;
