import React, {FC} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Instagram} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";

type Props = {
    url: string;
}
const InstagramButton:FC<Props> = ({url}) => {
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <Tooltip title={t('visit_instagram')!}>
                <IconButton onClick={()=> window.open(url, '_blank')}>
                    <Instagram/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default InstagramButton;
