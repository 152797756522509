import React, {Suspense} from 'react';
import {Route} from 'react-router-dom';
import {commonRoutes} from './common.routes';
import AuthConsumer from "../components/hoc/auth.consumer";
import CircularLoading from "../components/common/circular-loading";

export interface RouteType {
    path: string;
    component: any;
    exact?: boolean;
    needAuth?: boolean;
}

export const routes: RouteType[] = [
    ...commonRoutes,
];

export const renderRoutes = (routes: RouteType[]) =>
    routes
        .map(({
                  component: Component,
                  exact,
                  needAuth,
                  path
              }) => (
            <Route
                exact={exact}
                path={path}
                key={path}
                render={() => (
                    <AuthConsumer needAuth={needAuth}>
                        <Suspense fallback={<CircularLoading size={24}/>}>
                            <Component/>
                        </Suspense>
                    </AuthConsumer>
                )}/>
        ));

