import React, {Suspense, useEffect, useMemo, useState} from 'react';
import ErrorMessageComponent from "../common/error/error-message";
import {WifiOff} from "@material-ui/icons";
import {BrowserRouter, Redirect, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {renderRoutes, routes} from "../../routes";
import {ThemeProvider, useMediaQuery} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {useThemeModeProvider} from "../../providers/theme/theme-provider.context";
import {ThemeModeEnum} from "../../providers/theme/theme-provider.actions";
import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import ProjectSiderbarHoc from "../hoc/project-siderbar.hoc";
import {StoreProvider} from "../../providers/store/context";
import CircularLoading from "../common/circular-loading";
import darkTheme from '../../themes/dark.theme';
import defaultTheme from '../../themes/default.theme';

function App() {
    const prefersDarkMode: boolean = useMediaQuery('(prefers-color-scheme: dark)');
    const {t} = useTranslation();
    const {state: {theme: {mode}}} = useThemeModeProvider()
    const [isOnline, setOnline] = useState<boolean>(true);
    const theme = useMemo(
        () => {
            let _theme;
            switch (mode) {
                case ThemeModeEnum.day:
                    _theme = defaultTheme
                    break;
                case ThemeModeEnum.night:
                    _theme = darkTheme
                    break;
                default:
                    _theme = prefersDarkMode ? darkTheme : defaultTheme
                    break;
            }
            _theme = createTheme(_theme)
            return responsiveFontSizes(_theme)
        },
        [mode, prefersDarkMode],
    );
    useEffect(
        () => {
            window.addEventListener('load', () => {
                const updateOnlineStatus = () => {
                    setOnline(navigator.onLine);
                };
                window.addEventListener('online', updateOnlineStatus);
                window.addEventListener('offline', updateOnlineStatus);
            });
        },
        [],
    );
    useEffect(
        () => document.addEventListener('contextmenu', event => event.preventDefault()),
        []
    )
    if (!isOnline) {
        return (
            <ErrorMessageComponent
                icon={<WifiOff color={'error'} fontSize={'large'}/>}
                message={t('offline_error', 'Unable to connect to the Internet')}/>
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <StoreProvider>
                    <ProjectSiderbarHoc>
                        <Suspense fallback={<CircularLoading size={24}/>}>
                            <Switch>
                                {renderRoutes(routes)}
                                <Redirect to='/'/>
                            </Switch>
                        </Suspense>
                    </ProjectSiderbarHoc>
                </StoreProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
