import React, {FC} from 'react';
import {FirebaseDatabaseNode} from "@react-firebase/database";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import ProjectLink from "./project.link";
import {ProjectType} from "./project.types";

const path = 'projects/'
type Props = {
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}
const ProjectList: FC<Props> = ({onClick}) => {
    return (
        <React.Fragment>
            <FirebaseAuthConsumer>
                {({isSignedIn}) => {
                    return (
                        <FirebaseDatabaseNode
                            path={path}
                            isList
                            orderByChild={'ORDER'}
                        >
                            {(data) => {
                                const projects: { data: ProjectType, key: string }[] = data.value || [];
                                return (
                                    <React.Fragment>
                                        {projects.map(
                                            (project) => {
                                                return (
                                                    <ProjectLink
                                                        project={project.data}
                                                        isSignedIn={isSignedIn}
                                                        id={project.key}
                                                        key={project.key}
                                                        onClick={onClick}/>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                            }}
                        </FirebaseDatabaseNode>
                    )
                }}
            </FirebaseAuthConsumer>
        </React.Fragment>
    );
};

export default ProjectList;
