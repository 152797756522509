import React, {FC} from "react";
import {LanguagesEnum} from "../../../utils/i18n";
import ItalySquare from "../../../assets/icons/square-italy.svg";
import ItalyCircle from "../../../assets/icons/circle-italy.svg";
import Typography from "@material-ui/core/Typography";
import BritishSquare from "../../../assets/icons/square-united-kingdom.svg";
import BritishCircle from "../../../assets/icons/circle-united-kingdom.svg";


type Props = {
    country: LanguagesEnum
    square?: boolean;
    usePics?: boolean;
}
const LangItemComponent: FC<Props> = ({square, usePics, country}) => {
    switch (country) {
        case LanguagesEnum.it:
            if (usePics) {
                return (
                    <img
                        alt={'Italy FLag'}
                        src={square ? ItalySquare : ItalyCircle}
                        width={'22px'}
                        height={'22px'}/>
                )
            }
            return <Typography variant={"body2"}>&nbsp;IT&nbsp;</Typography>;
        case LanguagesEnum.en:
            if (usePics) {
                return (
                    <img
                        alt={'British FLag'}
                        src={square ? BritishSquare : BritishCircle}
                        width={'22px'}
                        height={'22px'}/>
                )
            }
            return <Typography variant={"body2"}>&nbsp;EN&nbsp;</Typography>
        default:
            throw new Error('Invalid flag')
    }
}

export default LangItemComponent
