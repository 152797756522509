import {lazy} from 'react';
import {RouteType} from "./index";

export const commonRoutes: RouteType[] = [
  {
    component: lazy(() => import('../components/container/homepage.container')),
    path: '/',
    exact: true,
  },
  {
    component: lazy(() => import('../components/container/bio.container')),
    path: '/bio',
    exact: true,
  },
  {
    component: lazy(() => import('../components/container/portfolio.container')),
    path: '/portfolio',
    exact: true,
  },
  {
    component: lazy(() => import('../components/container/auth/pictures-list.container')),
    path: '/portfolio/pictures',
    exact: true,
    needAuth: true,
  },
  {
    component: lazy(() => import('../components/container/project-details.container')),
    path: '/project/:title',
    exact: true,
    needAuth: false,
  },
  {
    component: lazy(() => import('../components/common/firebase-classic-login')),
    path: '/login',
    exact: true,
    needAuth: false,
  },
];
