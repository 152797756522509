import {FirebaseAuthConsumer} from "@react-firebase/auth";
import React, {FC} from "react";
import FirebaseClassicLogin from "../common/firebase-classic-login";
import {useLocation} from "react-router-dom";
import CircularLoading from "../common/circular-loading";

type Props = {
    needAuth?: boolean;
}
const AuthConsumer: FC<Props> = ({children, needAuth = false}) => {
    const {pathname} = useLocation()
    return (
        <FirebaseAuthConsumer>
            {({isSignedIn, providerId}) => {
                if (!providerId) {
                    return <CircularLoading size={24}/>
                }
                if (!isSignedIn && needAuth) {
                    return <FirebaseClassicLogin refreshUrl={pathname}/>
                }
                return children;
            }}
        </FirebaseAuthConsumer>
    )
};

export default AuthConsumer;
