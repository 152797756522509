import firebase from '@firebase/app';
import '@firebase/storage';
import '@firebase/analytics';
import '@firebase/auth';
import {useMemo} from "react";

const useFirebase = () => {
    const auth = useMemo(
        () => {
            try {
                if (firebase.auth)
                    return firebase.auth();
            } catch (e) {
                throw (e)
            }
        },
        []
    )
    /*const analytics = useMemo(
        () => {
            try {
                if (firebase.analytics)
                    return firebase.analytics();
            } catch (e) {
                throw (e)
            }
        },
        []
    )*/
    const storage = useMemo(
        () => {
            try {
                if (firebase.storage)
                    return firebase.storage();
            } catch (e) {
                throw (e)
            }
        },
        []
    )
    return {storage, auth};
};

export default useFirebase;
