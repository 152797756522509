import {SnackbarProps} from '@material-ui/core';
import {Actions} from './actions';

export type NotificationType = SnackbarProps & {
  severity?: import('@material-ui/lab').Color;
};

export interface NotificationState {
  notification: NotificationType;
  severity?: import('@material-ui/lab').Color;
}

export type NotificationActionType = (state: NotificationState, newState: NotificationType) => NotificationState

export type NotificationActionsType = {
  [Actions.TOAST_OPEN]: NotificationActionType;
  [Actions.TOAST_CLOSE]: NotificationActionType;
}

export interface NotificationAction extends Omit<NotificationType, 'open'> {
  type: Actions.TOAST_CLOSE | Actions.TOAST_OPEN;
}

export const notificationInitialState: NotificationState = {
  notification: {
    open: false,
    autoHideDuration: 4000,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  },
};

export const notificationActions: NotificationActionsType = {
  [Actions.TOAST_OPEN]: (state, newState) => ({
    ...state,
    notification: {
      ...state.notification,
      ...newState,
      open: true,
    },
  }),
  [Actions.TOAST_CLOSE]: (state, newState) => ({
    ...state,
    notification: {
      ...state.notification,
      ...newState,
      open: false,
    },
  }),
};
