import {Actions} from './actions';

export type FilteringType = {
  search?: string;
}

export interface FilteringState {
  filtering: FilteringType;
}

export type FilteringActionType = (state: FilteringState, newState: FilteringType) => FilteringState

export type FilteringActionsType = {
  [Actions.SEARCH_FILTER]: FilteringActionType;
}

export interface FilteringAction extends FilteringType {
  type: Actions.SEARCH_FILTER;
}

export const filteringInitialState: FilteringState = {
  filtering: {
    search: '',
  },
};

export const filteringActions: FilteringActionsType = {
  [Actions.SEARCH_FILTER]: (state, newState) => ({
    filtering: {
      search: newState.search,
    },
  }),
};
