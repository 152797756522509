import React, {FC} from 'react';
import {Button, Grid, Toolbar} from "@material-ui/core";
import {Link} from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import FirebaseLogout from "../firebase-logout";
import Hidden from "@material-ui/core/Hidden";
import LogoComponent from "../logo/logo.component";

const useStyles = makeStyles((theme) => ({
    menuButton: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    logoType: {
        textTransform: "uppercase",
        textDecoration: "none",
        color: theme.palette.text.primary
    },
    link: {
        textDecoration: 'unset',
        color: 'unset'
    }
}));


type Props = {
    handleDrawerToggle?: () => void
}
const NavbarComponent: FC<Props> = (props) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const {handleDrawerToggle} = props;
    return (
        <Toolbar>
            <Hidden lgDown>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Button component={Link} to={'/project'}>
                                    {t('portfolio')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button component={Link} to={'/bio'}>
                                    {t('bio')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <FirebaseLogout/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp implementation={"css"}>
                <Link to={'/'} className={classes.link}>
                    <LogoComponent/>
                </Link>
            </Hidden>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
                <MenuIcon/>
            </IconButton>
        </Toolbar>
    );
};

export default NavbarComponent;
