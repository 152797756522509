import React, {useCallback} from 'react';
import {Button} from "@material-ui/core";
import {IfFirebaseAuthed} from "@react-firebase/auth";
import useFirebase from "../../hooks/useFirebase";
import {useTranslation} from "react-i18next";

const FirebaseLogout = () => {
    const {auth} = useFirebase()
    const {t} = useTranslation()
    const logout = useCallback(
        async () => {
            try {
                await auth?.signOut()
                window.location.reload()
            } catch (e) {
                throw e
            }
        },
        [auth]
    )
    return (
        <React.Fragment>
            <IfFirebaseAuthed>
                {() => (
                    <Button
                        fullWidth
                        onClick={logout}>
                        {t('logout')}
                    </Button>
                )}
            </IfFirebaseAuthed>
        </React.Fragment>
    );
};

export default FirebaseLogout;
